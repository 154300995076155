import { observable, action, reaction } from 'mobx';
import { getSCRealObservable } from "../lib/ws";
export const MAX_PRICES_LENGTH = 500;

class PriceChartStore {
  @observable price = 0;
  isLoggedIn = false;

  constructor(authStore) {
    this.isLoggedIn = authStore.isLoggedIn;

    if (this.isLoggedIn) {
      this.getPriceDate();
    }

    reaction(
      () => ({
        isLoggedIn: authStore.isLoggedIn
      }),
      authObj => {
        this.isLoggedIn = authObj.isLoggedIn;
        this.getPriceDate();
      }
    );
  }

  getPriceDate = () => {
    getSCRealObservable().subscribe(cli => {
      cli.on('prices', data => {
        this.price = data.price;
      });
    });
  };

  @action.bound setViewMode(mode) {
    this.price = mode;
  }
}

export default (authStore) => new PriceChartStore(authStore);
