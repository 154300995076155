import { observable, action, reaction } from 'mobx';
import { ApiTradeRequest } from "../lib/ws";
import React from "react";

class TradeBotStore {
  @observable price = 0;
  isLoggedIn = false;

  constructor(authStore, snackbar) {
    this.isLoggedIn = authStore.isLoggedIn;
    this.snackbar = snackbar;

    if (this.isLoggedIn) {
    }

    reaction(
      () => ({
        isLoggedIn: authStore.isLoggedIn
      }),
      authObj => {
        this.isLoggedIn = authObj.isLoggedIn;
      }
    );
  }

  @action.bound startTradeBot(key, secret, delegate) {
    this.showSnackMsg('API Trading request is sent');
    ApiTradeRequest({
      key, secret, delegate
    })
      .then(data => {
        this.showSnackMsg('API Trading request is sent');
      });
  }

  /**
   *  Snackbar Popup message
   */
  @action.bound showSnackMsg(msg) {
    this.snackbar({
      message: () => (
        <>
          <span>
            <b>{msg}</b>
          </span>
        </>
      )
    });
  }
}

export default (authStore, snackbar) => new TradeBotStore(authStore, snackbar);
