import { observable, action, reaction } from "mobx";
import { getSCRealObservable } from "../lib/ws";

class OrderBookStore {
  @observable.shallow asks = [];
  @observable.shallow bids = [];
  isLoggedIn = false;

  constructor(authStore) {
    this.isLoggedIn = authStore.isLoggedIn;

    if (this.isLoggedIn) {
      this.getOrderBooks();
    }

    reaction(
      () => ({
        isLoggedIn: authStore.isLoggedIn
      }),
      authObj => {
        this.isLoggedIn = authObj.isLoggedIn;
        this.getOrderBooks();
      }
    );
  }

  getOrderBooks = () => {
    getSCRealObservable().subscribe(cli => {
      cli.on('orders', data => {
        try {
          this.asks = data.asks;
          this.bids = data.bids;
        } catch (e) {}
      });
    });
  };

  @action.bound setViewMode(mode) {
    this.price = mode;
  }
}

export default (authStore) => new OrderBookStore(authStore);
